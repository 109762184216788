import './studentProfile.css';
import React, {FC } from 'react';

import { useNavigate } from 'react-router-dom';
import Sidebar from 'Common/Sidebar/Sidebar';
import SidebarStudentProfile from 'Common/SidebarStudentProfile/SidebarStudentProfile';
import { CareerOptions } from 'Common/Constants';
import ProfileGirl from 'Assets/profileGirl.svg'
import ProfileBoy from 'Assets/profileBoy.svg'
import Contractor from 'Assets/contractor.svg'
import CheckCareer from 'Assets/checkCareer.png'
import FileUpload from 'Assets/fileUpload.png'
import ProfilePic from 'Assets/profilePic.png'
import Next from 'Assets/arrowNext.png'
import Back from 'Assets/arrowBack.png'
import { Button, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingBackdrop from 'Common/LoadingBackdrop';

const StudentProfile: FC<{  }> = ({  }) => {
  const nav = useNavigate()
const [profileData,setProfileData] = React.useState<any>(
  
)
const [profilePic,setProfilePic] = React.useState<any>(
  
)
const [loading, setLoading] = React.useState<
boolean
>(false)
const [newSkill,setNewSkill] = React.useState<string>('')
const [innerPageCount,setInnerPageCount] = React.useState<number>(1)
const [careerOptionData,setCareerOptionData] = React.useState<any>([{title:'Student',image:ProfileBoy,selected:false},
  {title:'Professional',image:ProfileGirl,selected:false},
  {title:'Contractor',image:Contractor,selected:false}])
  
const [currentSidebarPage,setCurrentSidebarPage] = React.useState<string>('Career')
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const handleUploadFile = React.useCallback(async(file:any,userToken?:string)=> {
      const userData = window.localStorage.getItem('user')
  const token = userData?JSON.parse(userData)?.token:''
  const email = userData?JSON.parse(userData)?.email:''
  const formData = new FormData();
  console.log(file)
  formData.append('resume', file)
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/uploadFile`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*',Authorization:'Bearer ' + token},
    body:formData

  }) .then(response => {
    return response.json();
  }).then((data:any) => {

   console.log(data)
  }).catch((error)=>{
    setSnackbarMessage(error.message)
  })
},[])
const handleClickArrow=React.useCallback((type:'Next'|'Previous'
)=>{
  if(type==='Next'){
    if(currentSidebarPage==='Career'){
     if(innerPageCount===1){
      setInnerPageCount(2)
     }
     else if(innerPageCount===2){
      setInnerPageCount(1)
      setCurrentSidebarPage('Account')
     }
    }
    if(currentSidebarPage==='Account'){
      if(innerPageCount===1){
        setInnerPageCount(2)
       }
       else if(innerPageCount===2){
        setInnerPageCount(1)
        setCurrentSidebarPage('Education')
       }
    }
    if(currentSidebarPage==='Education'){
      
      
        setInnerPageCount(1)
        setCurrentSidebarPage('Experiences')
       
    }
    if(currentSidebarPage==='Experiences'){
      
      
      setInnerPageCount(1)
      setCurrentSidebarPage('Skills')
     
  }
  if(currentSidebarPage==='Skills'){
    setInnerPageCount(1)
    setCurrentSidebarPage('Preferences')
  }
  if(currentSidebarPage==='Preferences'){
    if(innerPageCount===1){
      setInnerPageCount(2)
     }
     else if(innerPageCount===2){
      setInnerPageCount(1)
      setCurrentSidebarPage('Others')
     }
  }

    }
    if(type==='Previous'){
      if(currentSidebarPage==='Career'){
       if(innerPageCount===2){
        setInnerPageCount(1)
        
       }
      }
      if(currentSidebarPage==='Account'){
        if(innerPageCount===1){
          setInnerPageCount(2)
          setCurrentSidebarPage('Career')
         }
         else if(innerPageCount===2){
          setInnerPageCount(1)
         
         }
      }
      if(currentSidebarPage==='Education'){
        
        
          setInnerPageCount(2)
          setCurrentSidebarPage('Account')
         
      }
      if(currentSidebarPage==='Experiences'){
        
        
        setInnerPageCount(1)
        setCurrentSidebarPage('Education')
       
    }
    if(currentSidebarPage==='Skills'){
      setInnerPageCount(1)
      setCurrentSidebarPage('Experiences')
    }
    if(currentSidebarPage==='Preferences'){
      if(innerPageCount===1){
        setCurrentSidebarPage('Skills')
       }
       else if(innerPageCount===2){
        setInnerPageCount(1)
        
       }
    }
    if(currentSidebarPage==='Others'){
      setInnerPageCount(2)
      setCurrentSidebarPage('Preferences')
    }
  
      }   
   
},[currentSidebarPage,innerPageCount])
const handleUploadResume=(event:any)=>{
const fileSize:number = Number(((event?.target?.files?.[0].size/1024)/1024).toFixed(4))

  if(fileSize > 5) {
    console.log(fileSize)
    setSnackbarMessage('Only pdf. Max size 5 MB allowed')
   return
 }
 else{
  handleUploadFile(event?.target?.files?.[0],)
 }
}
const handleSelectCareerOption=React.useCallback((selectedIndex:number)=>{
  setCareerOptionData(careerOptionData?.map((item:any,index:number)=>{
    return {...item,selected:index===selectedIndex?true:false}
  }))
  let localProfile = {...profileData}
  setProfileData({...localProfile,carrier_stack:{...localProfile?.carrier_stack,carrierType:careerOptionData?.[selectedIndex]?.title}})
},[careerOptionData,profileData])
const handleSelect=React.useCallback((value:"Yes" | "No",type:string,date?:Date,index?:number)=>{
  const localProfileData = {...profileData}
     if(type==='citizenCountry' || type==='workAuth'){
setProfileData({...localProfileData,account:{...localProfileData.account,[type]:value==='Yes'?true:false}})
     }
     if(date){
      setProfileData({...localProfileData,education:localProfileData?.education?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,[type]:date}
        }
        else return item
      })})

     }
},[profileData])
const handleSelectExperenceDate=React.useCallback((type:string,date?:Date,index?:number)=>{
  const localProfileData = {...profileData}

  if(date){
    setProfileData({...localProfileData,workExperience:{...localProfileData.workExperience,experiences:localProfileData.workExperience.experiences?.map((item:any,indexInner:number)=>{
      if(index===indexInner){
        return {...item,[type]:date}
      }
      else return item
    })}})

   }
   
},[profileData])

const handleChangeOthers = React.useCallback((type:string)=>{
  const localProfileData = {...profileData}
  console.log(type,localProfileData?.others?.[type])
 setProfileData({...localProfileData,others:{...localProfileData?.others,[type]:[...localProfileData?.others?.[type],'']}})
},[profileData])
const deleteOthers=React.useCallback((type:string,index:number)=>{
  const localProfileData = {...profileData}
  const newItems = [
    ...localProfileData?.others?.[type].slice(0,index),
    ...localProfileData?.others?.[type].slice(index+1)
  ]

  setProfileData({...localProfileData,others:{...localProfileData?.others,[type]:newItems}})


},[profileData])
const addEducation = React.useCallback(()=>{
    const educationObject = {eduName:'',areaStudy:'',degree:'',school:'',startDate:'',endDate:'',specialization:''}
    setProfileData({...profileData,education:[...profileData?.education,educationObject]})
},[profileData])
const deleteSkill=React.useCallback((index:number)=>{
  const localProfileData = {...profileData}
  const newSkills = [
    ...localProfileData?.skills?.name.slice(0,index),
    ...localProfileData?.skills?.name.slice(index+1)
  ]

  console.log(newSkills)
  setProfileData({...localProfileData,skills:{...localProfileData?.skills,name:newSkills}})


},[profileData])
const deleteEducation = React.useCallback((index:number)=>{
  const localProfileData = {...profileData}
  const newEducation = [
    ...localProfileData?.education.slice(0,index),
    ...localProfileData?.education.slice(index+1)
  ]
  setProfileData({...localProfileData,education:newEducation})
},[profileData])
const addExperience = React.useCallback(()=>{
  const experienceObject ={expName:'',company:'',title:'',industry:'',jobType:'',location:'',startDate:'',endDate:'',currentlyWork:'',description:''}
  setProfileData({...profileData,workExperience:{...profileData.workExperience,experiences:[...profileData.workExperience.experiences,experienceObject]}})
},[profileData])

const deleteExperience = React.useCallback((index:number)=>{
  const localProfileData = {...profileData}
  const newExperience = [
    ...localProfileData?.workExperience?.experiences.slice(0,index),
    ...localProfileData?.workExperience?.experiences.slice(index+1)
  ]
  setProfileData({...localProfileData,workExperience:{...localProfileData.workExperience,experiences:newExperience}})
},[profileData])
const handleHaveExperience=React.useCallback((value:string)=>{
    setProfileData({...profileData,workExperience:{...profileData?.workExperience,haveExp:value}})
    if(value==='No'){
      setInnerPageCount(1)
      setCurrentSidebarPage('Skills')
    }

},[profileData])
const handleGetUserData = React.useCallback(async()=> {
  setLoading(true)
const userData = window.localStorage.getItem('user')
  const token = userData?JSON.parse(userData)?.token:''
  const email = userData?JSON.parse(userData)?.email:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/candDetails`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body:JSON.stringify(profileData)

  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    setLoading(false)

    setSnackbarMessage(data.message)
   
  }).catch((error)=>{
    setLoading(false)

    setSnackbarMessage(error.message)
  })
},[profileData])
const handleChangeInput = React.useCallback((event:any,key:string,index?:number,type?:string)=>{

  const localProfileData = {...profileData}
    if(key==='FirstName'){
setProfileData({...localProfileData,account:{...localProfileData?.account,firstName:event?.target?.value}})
    }
    if(key==='LastName'){
      setProfileData({...localProfileData,account:{...localProfileData?.account,lastName:event?.target?.value}})
    }
    if(key==='EducationName'){
        
      setProfileData({...localProfileData,education:localProfileData?.education?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,eduName:event?.target?.value}
        }
        else return item
      })})   
    }
    if(key==='AreaOfStudy'){
    
      setProfileData({...localProfileData,education:localProfileData?.education?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,areaStudy:event?.target?.value}
        }
        else return item
      })})
    }
    if(key==='Degree'){
     
      setProfileData({...localProfileData,education:localProfileData?.education?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,degree:event?.target?.value}
        }
        else return item
      })})
    }
    if(key==='School'){
      
      setProfileData({...localProfileData,education:localProfileData?.education?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,school:event?.target?.value}
        }
        else return item
      })})
    }
    if(key==='Specialization'){
      
      setProfileData({...localProfileData,education:localProfileData?.education?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,specialization:event?.target?.value}
        }
        else return item
      })})
    }
   
    if(key==='ExperienceName'){
      setProfileData({...localProfileData,workExperience:{...localProfileData?.workExperience,experiences:localProfileData?.workExperience?.experiences?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,expName:event?.target?.value}
        }
        else return item
      })}})
    }
    if(key==='ExperienceTitle'){
     
      setProfileData({...localProfileData,workExperience:{...localProfileData?.workExperience,experiences:localProfileData?.workExperience?.experiences?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,title:event?.target?.value}
        }
        else return item
      })}})
    }
    if(key==='Company'){
    
      setProfileData({...localProfileData,workExperience:{...localProfileData?.workExperience,experiences:localProfileData?.workExperience?.experiences?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,company:event?.target?.value}
        }
        else return item
      })}})
    }
    if(key==='JobType'){
    
      setProfileData({...localProfileData,workExperience:{...localProfileData?.workExperience,experiences:localProfileData?.workExperience?.experiences?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,jobType:event?.target?.value}
        }
        else return item
      })}})
    }
    if(key==='Industry'){
     
      setProfileData({...localProfileData,workExperience:{...localProfileData?.workExperience,experiences:localProfileData?.workExperience?.experiences?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,industry:event?.target?.value}
        }
        else return item
      })}})
    }
    if(key==='Location'){

      setProfileData({...localProfileData,workExperience:{...localProfileData?.workExperience,experiences:localProfileData?.workExperience?.experiences?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return {...item,location:event?.target?.value}
        }
        else return item
      })}})
    }

    if(key==='Skill'){
      setNewSkill(event?.target?.value)
    }
       
   
    if(key==='WorkLocation'){
      setProfileData({...localProfileData,workPrefrence:{...localProfileData?.workPrefrence,prefWorkLocation:event?.target?.value}})
    }
    if(key==='PrefJobType'){
      setProfileData({...localProfileData,workPrefrence:{...localProfileData?.workPrefrence,prefJobType:event?.target?.value}})
    }
    if(key==='NoticePeriod'){
      setProfileData({...localProfileData,workPrefrence:{...localProfileData?.workPrefrence,NoticePeriod:event?.target?.value}})
    }
    if(key==='DesiredSalary'){
      setProfileData({...localProfileData,workPrefrence:{...localProfileData?.workPrefrence,desiredSal:event?.target?.value}})
    }

    if(key==='CareerGoals'){
      setProfileData({...localProfileData,workPrefrence:{...localProfileData?.workPrefrence,carGoals:event?.target?.value}})
    }
    if(key==='Linkedin'){
      setProfileData({...localProfileData,carrier_stack:{...localProfileData?.carrier_stack,linkedinProfile:event?.target?.value}})
    }
    if(key==='Region'){
      setProfileData({...localProfileData,account:{...localProfileData?.account,region:event?.target?.value}})
    }
    if(key==='Country'){
      setProfileData({...localProfileData,account:{...localProfileData?.account,country:event?.target?.value}})
    }
    if(key==='Email'){
      setProfileData({...localProfileData,account:{...localProfileData?.account,email:event?.target?.value}})
    }
    if(key==='Phone'){
      setProfileData({...localProfileData,account:{...localProfileData?.account,phone:event?.target?.value}})
    }
    if(type==='other'){
        
      setProfileData({...localProfileData,others:{...localProfileData?.others,[key]:localProfileData?.others?.[key]?.map((item:any,indexInner:number)=>{
        if(index===indexInner){
          return event?.target?.value
        }
        else return item
      })}})   
    }
       
    
},[profileData])
const addSkill = React.useCallback((skill:string)=>{
  if(skill?.trim()?.length===0) return
  let localProfile = {...profileData}
  setProfileData({...localProfile,skills:{...localProfile?.skills,name:[...localProfile?.skills?.name,skill]}})
setNewSkill('')
},[profileData])
const renderCareer=()=>{
  return (<div className='studentType'>
   <span className='relevantText'>What is revelant for you?</span>
    <div className='avatarContainer'>
   {careerOptionData?.map((item:any,index:number)=>{
     return (<div onClick={()=>handleSelectCareerOption(index)} className='avatarWrapper' style={{borderBottom:item?.selected?'2px solid #0250C5':''}}>
      <img className='profilePhoto' src={item?.image}  />
      <span className='profileText'>{item?.title}</span>
      {item?.selected && <img src={CheckCareer} className='checkCareer' />}
    </div>)
   }) }
    
    </div>
   
  
  </div>)
}
const renderAccount=()=>{
  return (<div className='accountWrapper'>
   <span className='aboutYouText'>About You</span>
   <span className='resumeLabel'>First Name</span>
    <TextField  classes={{root:"inputFieldLinkedin"}} onChange={(e)=>handleChangeInput(e,'FirstName')} value={profileData?.account?.firstName} type='text' />
    <span className='resumeLabel'>Last Name</span>
    <TextField  classes={{root:"inputFieldLinkedin"}} onChange={(e)=>handleChangeInput(e,'LastName')} value={profileData?.account?.lastName} type='text' />
    <div className='selectProfileImageWrapper'>
      <img className='selectProfileImage' src={ProfilePic}/>
      <span className='selectProfileText'>CHOOSE PHOTO</span>
      <span className='selectProfileText'>REMOVE PHOTO</span>
    </div>
  
  </div>)
}
const renderEducation=()=>{
  return <div className='educationOuter'>
  <span className='youreducationText'>Your Education</span>
 {
   profileData?.education?.map((item:any,index:number)=>{
    return (
    <div className='educationWrapper'>
      
     <div className='educationIndexContainer'>
     <div className='educationIndex'>{index+1}</div>
     <TextField onChange={(e)=>handleChangeInput(e,'EducationName',index)} classes={{root:"inputFieldLinkedin"}} value={item?.eduName} placeholder='Name' type='text' />
     {profileData?.education?.length>1 &&<DeleteIcon  onClick={()=>deleteEducation(index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}

     </div>
      <span className='resumeLabel'>Area of study</span>
      <TextField onChange={(e)=>handleChangeInput(e,'AreaOfStudy',index)}  classes={{root:"inputFieldLinkedin"}} value={item?.areaStudy} type='text' />
      <span className='resumeLabel'>Degree</span>
      <TextField onChange={(e)=>handleChangeInput(e,'Degree',index)} classes={{root:"inputFieldLinkedin"}} value={item?.degree} type='text' />
      <span className='resumeLabel'>School</span>
      <TextField onChange={(e)=>handleChangeInput(e,'School',index)}
 classes={{root:"inputFieldLinkedin"}} value={item?.school} type='text' />
      <div className='aboutYouSelectWrapper'>
      <div className='isCitizenWrapper' >
 
 <span className='resumeLabel'>Start Date</span>
 <DatePicker className='isCitizenSelect' selected={item?.startDate} onChange={(date) => handleSelect('No','startDate',date!,index)} />
</div>
<div className='isCitizenWrapper' >

<span className='resumeLabel'>End Date</span>
<DatePicker className='isCitizenSelect' selected={item?.endDate} onChange={(date) => handleSelect('No','endDate',date!,index)} />

</div>
    </div>
    <span className='resumeLabel'>Specialization(Optional)</span>
    <TextField onChange={(e)=>handleChangeInput(e,'Specialization',index)} classes={{root:"inputFieldLinkedin"}} value={item?.specialization} type='text' />  
      </div>)
  })
 }
   <div className='addIconEducation' > <AddCircleIcon onClick={addEducation} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  /></div>

  </div>
   
}
const renderYourExperience=()=>{
  return  <div className='experienceWrapper'>
  <span className='yourWorkEx'>Your work experiences</span>
 {
   profileData?.workExperience?.experiences?.map((item:any,index:number)=>{
    return (
    <>
    <div style={{paddingTop:30}} className='educationIndexContainer'>
     <div className='educationIndex'>{index+1}</div>
     <TextField onChange={(e)=>handleChangeInput(e,'ExperienceName',index)} classes={{root:"inputFieldLinkedin"}} value={item?.expName} placeholder='Name' type='text' />
     {profileData.workExperience.experiences?.length>1 &&<DeleteIcon  onClick={()=>deleteExperience(index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}

     </div>
      <span className='resumeLabel'>Title</span>
      <TextField onChange={(e)=>handleChangeInput(e,'ExperienceTitle',index)}  classes={{root:"inputFieldLinkedin"}} value={item?.title} type='text' />
      <span className='resumeLabel'>Company</span>
      <TextField onChange={(e)=>handleChangeInput(e,'Company',index)} classes={{root:"inputFieldLinkedin"}} value={item?.company} type='text' />
      <span className='resumeLabel'>Industry</span>
      <TextField onChange={(e)=>handleChangeInput(e,'Industry',index)}  classes={{root:"inputFieldLinkedin"}} value={item?.industry} type='text' />
      <span className='resumeLabel'>Job Type</span>
      <TextField onChange={(e)=>handleChangeInput(e,'JobType',index)} classes={{root:"inputFieldLinkedin"}} value={item?.jobType} type='text' />
      <span className='resumeLabel'>School</span>
      <TextField onChange={(e)=>handleChangeInput(e,'Location',index)} classes={{root:"inputFieldLinkedin"}} value={item?.location} type='text' />
      <div className='aboutYouSelectWrapper'>
      <div className='isCitizenWrapper' >
 
 <span className='resumeLabel'>Start Date</span>
 <DatePicker className='isCitizenSelect' selected={item?.startDate} onChange={(date) => handleSelectExperenceDate('startDate',date!,index)} />

</div>
<div className='isCitizenWrapper' >

<span className='resumeLabel'>End Date</span>
<DatePicker className='isCitizenSelect' selected={item?.endDate} onChange={(date) => handleSelectExperenceDate('startDate',date!,index)} />

</div>
    </div>
    {/* <span className='resumeLabel'>Description(Optional)</span>
    <TextField onChange={(e)=>handleChangeInput(e,'WorkDescription',index)} classes={{root:"inputFieldLinkedin"}} value={item?.description} type='text' />   */}
      </>
      )
  })
 }
    <div className='addIconEducation' > <AddCircleIcon onClick={addExperience} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  /></div>

  </div>
   
}
const renderExperiences=()=>{
  return (
   <div className='accountWrapper'>
   <span className='aboutYouText'>Do you have any work experience?</span>
   <div className='experienceSelectionButtonWrapper' onClick={()=>handleHaveExperience('Yes')}><button className='experienceSelectionButton'>Yes</button>
   <button className='experienceSelectionButton' onClick={()=>handleHaveExperience('No')}>No</button></div>
  
  </div>
   
  
  )
}

const renderSkills=React.useCallback(()=>{
  return (<>
  
   
   <div className='skillsWrapper'>
   <span className='aboutYouText'>What are your skills?</span>
   <span className='resumeLabel'>Search and add skill</span>
   <TextField value={newSkill} onChange={(e)=>handleChangeInput(e,'Skill')} classes={{root:"inputFieldLinkedin"}} onBlur={(e)=>addSkill(e?.target?.value)}  type='text' />
   <span className='resumeLabel'>Found these skills from your resume, you can remove or add more   </span>
   <Stack direction="row" spacing={1} className='chipOuter'>
   {profileData?.skills?.name?.map((item:string,index:number)=>{
    return (      <Chip  classes={{root:'skillChip'}}  color="primary"  label={item} variant="outlined" onDelete={(e)=>deleteSkill(index)} />
  )
   
    

   })}
     </Stack>

  </div>
  
  </>)
},[profileData,newSkill])
const renderPreferences=()=>{
  return (<>
  <div className='prefrencesWrapper'>
      <span className='aboutYouText'>Do you have any Preferences?</span>
      <span className='resumeLabel'>Prefered Work Location</span>
      <TextField onChange={(e)=>handleChangeInput(e,'WorkLocation')} classes={{root:"inputFieldLinkedin"}} value={profileData?.workPrefrence?.location}  type='text' />
      <span className='resumeLabel'>Prefered Job Type (Optional)</span>
      <TextField onChange={(e)=>handleChangeInput(e,'PrefJobType')} classes={{root:"inputFieldLinkedin"}} value={profileData?.workPrefrence?.prefJobType} type='text' />
      <span className='resumeLabel'>Notice Period in days (How soon can you join?) *</span>
      <TextField onChange={(e)=>handleChangeInput(e,'NoticePeriod')} classes={{root:"inputFieldLinkedin"}} value={profileData?.workPrefrence?.NoticePeriod} type='text' />
      <span className='resumeLabel'>Desired Salary (Optional)      </span>
      <TextField onChange={(e)=>handleChangeInput(e,'DesiredSalary')} classes={{root:"inputFieldLinkedin"}} value={profileData?.workPrefrence?.desiredSal} type='text' />
      <span className='resumeLabel'>Career Goals (Optional)      </span>
      <TextField onChange={(e)=>handleChangeInput(e,'CareerGoals')} classes={{root:"inputFieldLinkedin"}} value={profileData?.workPrefrence?.carGoals} type='text' />
      
   
      </div>
  
  </>)
}
const renderCover=()=>{
  return (<>
   <div className='coverWrapper'>
      <span className='aboutYouText'>Upload a cover letter</span>
      <span className='resumeLabel'>Upload Cover Letter</span>
  <label htmlFor="filePicker"   >  <div className='inputFieldLinkedinUpload' >
  <img  className='iconFileUpload' src={FileUpload} /> drag file or click to browse
  
  </div> </label>
  <input id="filePicker" onChange={(e)=>handleUploadResume(e)} accept="application/pdf" style={{display:"none"}} type={"file"} />
  <span className='resumeBottomText'>Only pdf. Max size 5 MB</span>
  <span className='resumeBottomText'>If you do not have a cover letter, You can download, edit and reupload this sample letter  </span>
  <span className='coverLinkText'>Sample cover letter  </span>
  <span className='coverLinkText'>Skip this for now  </span>
      </div>
  
  </>
   
   
  
)
}
const renderOthers=()=>{
  return (<>
   <div className='otherWrapper'>
   <span className='otherHeader'>Select a section to add</span>
   {profileData?.others?.certification?.length!==0 && <div className='otherSection' >
    <label  className='documentOtherText' htmlFor="filePickerDoc"   >Certifications </label>
   {profileData?.others?.certification?.map((item:string,index:number)=>{
    return (<div className='educationIndexContainer' style={{marginTop:6}}>
       <div className='educationIndex'>{index+1}</div>
      <TextField onChange={(e)=>handleChangeInput(e,'certification',index,'other')} classes={{root:"inputFieldLinkedin"}} value={item}  type='text' />
      {<DeleteIcon  onClick={()=>deleteOthers('certification',index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}
    </div>)
    
   })}
  </div>}
  {profileData?.others?.courses?.length!==0 && <div className='otherSection'>
    <label  className='documentOtherText' htmlFor="filePickerDoc"   >Courses </label>
   {profileData?.others?.courses?.map((item:string,index:number)=>{
    return (<div className='educationIndexContainer' style={{marginTop:6}}>
       <div className='educationIndex'>{index+1}</div>
      <TextField onChange={(e)=>handleChangeInput(e,'courses',index,'other')} classes={{root:"inputFieldLinkedin"}} value={item}  type='text' />
      {<DeleteIcon  onClick={()=>deleteOthers('courses',index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}
    </div>)
    
   })}
  </div>}
  {profileData?.others?.projectThesis?.length!==0 && <div className='otherSection'>
    <label  className='documentOtherText' htmlFor="filePickerDoc"   >Project Thesis </label>
   {profileData?.others?.projectThesis?.map((item:string,index:number)=>{
    return (<div className='educationIndexContainer' style={{marginTop:6}}>
       <div className='educationIndex'>{index+1}</div>
      <TextField onChange={(e)=>handleChangeInput(e,'projectThesis',index,'other')} classes={{root:"inputFieldLinkedin"}} value={item}  type='text' />
      {<DeleteIcon  onClick={()=>deleteOthers('projectThesis',index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}
    </div>)
    
   })}
  </div>}
  {profileData?.others?.publications?.length!==0 && <div className='otherSection'>
    <label  className='documentOtherText' htmlFor="filePickerDoc"   >Publications</label>
   {profileData?.others?.publications?.map((item:string,index:number)=>{
    return (<div className='educationIndexContainer' style={{marginTop:6}}>
       <div className='educationIndex'>{index+1}</div>
      <TextField onChange={(e)=>handleChangeInput(e,'publications',index,'other')} classes={{root:"inputFieldLinkedin"}} value={item}  type='text' />
      {<DeleteIcon  onClick={()=>deleteOthers('publications',index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}
    </div>)
    
   })}
  </div>}
  {profileData?.others?.patents?.length!==0 && <div className='otherSection'>
    <label  className='documentOtherText' htmlFor="filePickerDoc"   >Patents</label>
   {profileData?.others?.patents?.map((item:string,index:number)=>{
    return (<div className='educationIndexContainer' style={{marginTop:6}}>
       <div className='educationIndex'>{index+1}</div>
      <TextField onChange={(e)=>handleChangeInput(e,'patents',index,'other')} classes={{root:"inputFieldLinkedin"}} value={item}  type='text' />
      {<DeleteIcon  onClick={()=>deleteOthers('patents',index)} fontSize='large'  htmlColor='#2a6bd8' className='welcomeArrow'  />}
    </div>)
    
   })}
  </div>}
 
   <AddCircleIcon style={{marginTop:8}} fontSize='large' htmlColor='#2a6bd8' className='welcomeArrow'  />
   <div className='documentOther'>
   <span > <label className='documentOtherText' onClick={()=>handleChangeOthers('certification')} htmlFor="filePickerDoc"   >Certifications </label></span>
  
   <span > <label className='documentOtherText' onClick={()=>handleChangeOthers('courses')} htmlFor="filePickerDoc"   >Courses</label></span>
   <span ><label className='documentOtherText' onClick={()=>handleChangeOthers('projectThesis')} htmlFor="filePickerDoc"   >Project / Thesis </label>  </span>
   <span ><label  className='documentOtherText' onClick={()=>handleChangeOthers('publications')} htmlFor="filePickerDoc"   >Publications </label>  </span>
    <span ><label  className='documentOtherText' onClick={()=>handleChangeOthers('patents')} htmlFor="filePickerDoc"   >Patents</label>
    </span>
   </div>
  </div>
   
  
  </>)
}
const renderAddress=()=>{ 
  return (<div className='accountWrapper'>
    <span className='aboutYouText'>About You</span>
    <span className='resumeLabel'>Phone</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Phone')} classes={{root:"inputFieldLinkedin"}}value={profileData?.account?.phone} type='number' />
     <span className='resumeLabel'>Email</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Email')} classes={{root:"inputFieldLinkedin"}} value={profileData?.account?.email} type='text' />
     <span className='resumeLabel'>Country</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Country')} classes={{root:"inputFieldLinkedin"}} value={profileData?.account?.country} type='text' />
     <span className='resumeLabel'>Region</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Region')} classes={{root:"inputFieldLinkedin"}} value={profileData?.account?.region} type='text' />
     <div className='aboutYouSelectWrapper'>
     <div className='isCitizenWrapper' >
 
  <span className='resumeLabel'>Citizen of said Contury</span>
  <Select
    className='isCitizenSelect'
    value={profileData?.account?.citizenCountry===true?'Yes':'No'
    }
    onChange={(e)=>handleSelect(e?.target?.value as "Yes" | "No",'citizenCountry')}
  >
    <MenuItem value={'Yes'}>Yes</MenuItem>
    <MenuItem value={'No'}>No</MenuItem>
  </Select>
</div>
<div className='isCitizenWrapper' >
 
 <span className='resumeLabel'>Work Authorization </span>
 <Select
   className='isCitizenSelect'
   value={profileData?.account?.workAuth===true?'Yes':'No'}
   onChange={(e)=>handleSelect(e?.target?.value as 'Yes'|'No','workAuth')}
   
   
 >
   <MenuItem value={'Yes'}>Yes</MenuItem>
   <MenuItem value={'No'}>No</MenuItem>
 </Select>
</div>
     </div>
   
   </div>)
}
const renderResume=()=>{
  return (<div className='resumeWrapper'>
   <span className='relevantText'>Upload resume or give a link to profile</span>
   <span className='resumeLabel'>Link to LinkedIn Profile   </span>
   <TextField onChange={(e)=>handleChangeInput(e,'Linkedin')} classes={{root:"inputFieldLinkedin"}} value={profileData?.carrier_stack?.linkedinProfile}  type='text' />
   <span className='resumeLabel'>Upload your Resume   </span>  
  <label htmlFor="filePicker"   >  <div className='inputFieldLinkedinUpload' >
  <img  className='iconFileUpload' src={FileUpload} /> Only pdf. Max size 5 MB
  
  </div> </label>
  <input id="filePicker" onChange={(e)=>handleUploadResume(e)} accept="application/pdf" style={{display:"none"}} type={"file"} />
  <span className='resumeBottomText'>Only pdf. Max size 5 MB</span>
  </div>)
}
React.useEffect(()=>{
  if(snackbarMessage){
    const close = setTimeout(()=>{
      setSnackbarMessage('')
   clearTimeout(close)
    },5000)
  }
},[snackbarMessage])

React.useEffect(()=>{

const date = new Date()
  
   setProfileData(
    {
      carrier_stack:{carrierType:'Professional',linkedinProfile:'https://www.linkedin.com',resumeURL:''},
      account:{firstName:'Shubham',lastName:'Sharma',profileURL:'',phone:'9009909922',email:'johndoe@gmail.com',country:'China',region:'Beijin',citizenCountry:true,workAuth:true},
  education:[{eduName:'Graduation',areaStudy:'Computer Science',degree:'B.Tech',school:'NIT Delhi',startDate:date,endDate:date,specialization:''}],
  workExperience:{haveExp:'',experiences:[{expName:'Intern, ABC Inc. , July 2010 - Dec 2010',company:'Google',title:'Assoicate Cloud Engineer',industry:'AI',jobType:'Full Time',location:'Edmonton, Alberta',startDate:date,endDate:date,currentlyWork:'',description:''}]},
  skills:{name:['Python','SQL','R','Machine Learning','Predictive Modelling','Data Wrangling','Statistics','Cloud Computing','Machine Learning','d3.js','Scala'],other:[]},
  workPrefrence:{prefWorkLocation:'Edmonton, Alberta',prefJobType:'Full Time',NoticePeriod:'14',desiredSal:'~ $120k - $150k',carGoals:''},
others:{certification:[],courses:[],projectThesis:[],publications:[],patents:[]}

   }
  )
},[])

  return (
    <div className="studentProfileWrapper">
      <SidebarStudentProfile    currentSidebarPage={currentSidebarPage} innerPageCount={innerPageCount}  /> 
      
      
     <div className='studentProfileContent'>
<div className='saveButtonOuter'>     <Button className='saveButton' onClick={handleGetUserData} >Save</Button>
<Button className='saveButton' onClick={()=>nav('/portal')} >Close</Button></div>

    {currentSidebarPage==='Career' && innerPageCount!==2 && renderCareer()}
    {currentSidebarPage==='Career' && innerPageCount===2 && renderResume()}
    {currentSidebarPage==='Account' && innerPageCount!==2 && renderAccount()}
    {currentSidebarPage==='Account' &&  innerPageCount===2 && renderAddress()}
    {currentSidebarPage==='Education' && renderEducation()}
    {currentSidebarPage==='Experiences' && (profileData?.workExperience?.haveExp==='' && profileData?.carrier_stack?.carrierType==='Student'?renderExperiences():renderYourExperience())}
    {currentSidebarPage==='Skills' && renderSkills()}
    {currentSidebarPage==='Preferences' && innerPageCount!==2 &&  renderPreferences()}
    {currentSidebarPage==='Preferences' && innerPageCount===2 &&  renderCover()}
    {currentSidebarPage==='Others' && renderOthers()}
    </div>
    <div className='nextIconWrapper'>
      <img src={Back} style={{visibility:currentSidebarPage==='Career' && innerPageCount===1?'hidden':'visible'}}   className='welcomeArrow' onClick={()=>handleClickArrow('Previous')} />
      <img src={Next} style={{visibility:currentSidebarPage==='Others'?'hidden':'visible'}} className='welcomeArrow' onClick={()=>handleClickArrow('Next')} /> 
    </div>
    <GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />
    <LoadingBackdrop isVisible={loading} />

    </div>
  );
}

export default StudentProfile;