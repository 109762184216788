import './Jobcard.css';
import React, { Children } from 'react';

function Jobcard() {
  return (
  <div className='JobcardDetail'>
    <div className='jobName'> AWS Technical Architect</div>
    <div className='codeLine'> AWS Technical Architect</div>
    <div className='profileName'>Data Engineering</div>
    <div className='experiance'>Edmonton, CA, + 3</div>
    <div className='dateValue'>15 July 2020</div>
  </div>
  );
}

export default Jobcard;