import "./Portal.css"
import '../SignUpPage/signup.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import SearchBar from 'Common/SearchBar/SearchBar';
import Windows from '../../Assets/windows.png'
import Jobtab from 'Common/Jobtab/Jobtab';
import Jobcard from 'Common/Jobcard/Jobcard';
import InviteUsers from "Views/InviteUsers/InviteUsersPopup";
import InviteUserList from "Views/InviteUsers/InviteUsers";
import RecruiterWelcome from "../RecruiterWelcome/RecruiterWelcome";
function Portal() {
  const nav = useNavigate()
  const [activeTab,setActiveTab] = React.useState<string>('welcome')
  const [userName,setUserName] = React.useState<string>('')

 const handleToggleBtn=React.useCallback((value:string)=>{

  setActiveTab(value);
  
  },[])

 const renderJobs=()=>{
  return (<div className="main">
    <div className='titleTextPortal'>
  <div>        <div className='posting'>Posting</div>
  <div className='add_portal'>Add from Portal</div></div>
    </div>
    <div className="jobTab">
        <Jobtab activeTab={activeTab} handleToggleBtn={handleToggleBtn} />
    </div>
  
    <div>
    {activeTab==='Amazone' ? <Jobcard />  : activeTab==='Linkedin' ? <> <Jobcard />  <Jobcard />  <Jobcard /></> : <div style={{margin:20}}>NO RECOD ADDED!</div>} 
    </div>
    
    </div>)      
 }
 const renderInvite = ()=>{
  return  <div className="main"> <InviteUserList /></div>
 }
 React.useEffect(()=>{
  const userData = window.localStorage.getItem('user');
 
    if(userData){
      const parsedData = JSON.parse(userData);
    
      setUserName(parsedData?.user?.name)
      
    }
   },[userName])
  return (
    <div className="portal">
      <PortalSideBar activeTab={activeTab} changeTab={handleToggleBtn} /> 
      <SearchBar /> 
      {activeTab === "welcome" &&  <RecruiterWelcome  />}
     {(activeTab==='Amazone' || activeTab==='Linkedin' || activeTab==='Dice') && renderJobs()}
     {activeTab==='settings' && renderInvite()}
     
    </div>
  );
}

export default Portal;