import './dashboard.css';
import React from 'react';
import { Typography } from '@mui/material';
import { Navigate, redirect, useNavigate  } from 'react-router-dom';

function Dashboard() {
  const [userName,setUserName] = React.useState<string>('')
  const [email,setEmail] = React.useState<string>('')
  const nav = useNavigate()

 React.useEffect(()=>{
//   const userData = localStorage.getItem('user')
//   if(userData){
//     const parsedData = JSON.parse(userData)
//     setUserName(parsedData.name)
//     setEmail(parsedData.email)
    
//   }
 },[])
  return (
   <>
   <div className="body">
    <div className="banner">
        <div className="header">
            <a href=""><img src="./Assets/Logo.svg" alt="logo" /></a>
            <div onClick={()=> nav('/signin')}  className="signInBtn">Sign In</div>
            <div onClick={()=> nav('/signup')} className="signUpBtn">SIGN UP</div>

        </div>
        
        <div className="firstCard">

        </div>
        <div className="secondCard">

        </div>
        <div className="signInCard">

        </div>
        <div className="signInBottomCard">

        </div>
        <div className="signUpCard">

        </div>
        <div className="signUpBottomCard">

        </div>
        
        
        
            <div className="mainHeading">Right Candidate for the Right Career Track</div>
            <div className="smallText">R2O Transforms the “Future of Work”</div>
       
        <div className="bannerImg">
            <img src="./Assets/bannerImg.svg" alt="banner"/>
        </div>
    </div>
    <div className="aboutCard">
        <div className="aboutCardInnerBox">
            <img src="Assets/topDots.png" className="topDots" alt=""/>
            <img src="Assets/rightDots.png" className="rightDots" alt=""/>
            
            <div className="recruiterImg">
                <img src="./Assets/recruiterIllustration.svg" alt="banner"/>
            </div>
            <div className="recruiterBox">
<div className="recruiterHeading">Attract The Right Talent</div>
<div className="recruiterSubtext">Attract the right talent for the right job, discuss with a talent pool that comes with great ideas, higher commitments, and clear vision of their career decision.

</div>
    <div className="recruiterSubtextTwo">

    Reduce unproductive time for candidate search, resume match, pre-screening efforts. Empower the candidates to demonstrate their career aspirations and thought process to drive the future of work.</div>

            </div>
            
        </div>
        <div className="candidateCardInnerBox">
            <img src="Assets/bottomRight.png" className="bottomRight" alt=""/>
            <img src="Assets/bottomDots.png" className="bottomDots" alt=""/>
            <div className="candidateImg">
                <img src="./Assets/candidateIllustration.svg" alt="banner"/>
            </div>
            <div className="candidateBox">
                <img src="Assets/leftDots.png" className="leftDots" alt=""/>
               
<div className="recruiterHeading">Attain Your Career Aspirations</div>
<div className="recruiterSubtext">Empowering, Enabling, and Guiding candidates to determine and accomplish their career aspirations.

  

</div>
    <div className="recruiterSubtextTwo">
        An outcome-driven recruitment solution that increases the success rate of position fulfillment, reduces cycle time for hire-to-onboard and guarantees employee retention.</div>

            </div>
            
        </div>
    </div>
    <div className="sectionThree">
        <div className="sectionThreeHeading">Driving the Future of Work</div>
        <div className="sectionThreeCard">
            <div className="section_card_one">
                <img src="./Assets/collaborative_illustration.svg" alt=""/>
                <div className="section_card_text">A global collaborative platform supporting candidates to make the right career decisions and Recruiters to select the most deserving candidate.</div>
            </div>
            <div className="section_card_one">
                <img src="./Assets/ai_illustration.svg" alt=""/>
                <div className="section_card_text">Artificial Intelligence and Deep Learning with cognitive analytics to support decision making for candidates and recruiters.</div>
            </div>

        </div>
       
    </div>
    <div className="sectionFour">
        <div className="sectionThreeHeading">R2O in Numbers Per Day</div>
        <div className="topCard">
            <div className="numberCard">
                <div className="numberCardOne">
                    <div className="numberone">17.1K</div>
                    <div className="viewText">Views</div>
                    <img src="Assets/1Dots.png" className="firstDot" alt=""/>
                </div>
            </div>
            <div className="numberCard">
                <div className="numberCardTwo">
                    <div className="applicantText">Applicants</div>
                    <img src="Assets/1Dots.png" className="firstDot" alt=""/>
                    <div className="numberTwo">13.4K</div>
                  
                </div>
            </div>
        </div>
        <div className="bottomCard">
            <div className="preScreenedCard">
                    <div className="pre-screened">Pre-screened</div>
                    <div className="firstText">7,200</div>
                    <img src="Assets/2Dots.png" className="secondDot" alt=""/>
                    <img src="Assets/3Dots.png" className="thirdDot" alt=""/>
            </div>
            <div className="eachMinuteCard">
                <div className="countText">5</div>
                <div className="secondText">Pre-screened <br/>
                    Each Minute</div>
                <img src="Assets/2Dots.png" className="secondDot" alt=""/>
                <img src="Assets/4Dots.png" className="fourthDot" alt=""/>
            </div>
        </div>
        
    </div>
    <div className="sectionFive">
        <div className="sectionFiveImage">
        <img src="Assets/testimonial_illustration.svg" alt=""/>
        </div>
            <img src="Assets/format_quote.png" className="format_quote" alt=""/>
        <div className="blueBg"></div>
        <div className="testimonial_card">
                <div className="name">Linda Hamilton</div>
                <div className="position">Scientist Molecular Biology</div>
                <div className="testimonial">It was a unique job search experience on R2O. I felt empowered to make my own career decision. The virtual assistance right from the time I signed up was extremely value-added. I got excellent career guidance, adequate preparation before I selected the position and the best part was the self-scheduled interview. The entire process was crisp, fast and I was well informed all along. Thanks, R2O for helping me secure the dream job I was aspiring for. It was a life-changer for me.</div>
        </div>
    </div>
    <div className="sectionSix">
        <img src="Assets/Group6.svg" className="sectionSixImage" alt=""/>
        <div className="sectionSixHeading">Get Started With R2O Today</div>
        <div className="signUpButtonBox">
            <div onClick={()=> nav('/signup')}  className="signUpButtonMain">
                Sign Up For Free
            </div>
            </div>
    </div>
    <div className="footer">
        <div className="copyRight"> 
            <div className="copyRightImg"><img src="Assets/copyright.png" alt=""/></div>
            <div className="copyRightText">Copyright 2020 | All Rights Reserved</div>
        </div>
        <div ><img src="Assets/LogoClr.svg" alt=""/></div>
        <div className="footerLink">
            <a className="footerText" href="">About</a>
            <a className="footerText" href="">Contact</a>
            <a className="footerText" href="">Privacy</a>
        </div>
  
    </div>
</div>
   </>
  );
}

export default Dashboard;
