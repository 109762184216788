

import React, { Children } from 'react';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import './Jobtab.css'
export interface JobtabProps {
    activeTab:string
    handleToggleBtn:(otp: string)=>void
}
export const Jobtab: React.FC<JobtabProps> = ({
    activeTab,
    handleToggleBtn
}) => {
  return (
<>
<Box sx={{}}>
  <Tabs value={activeTab} >
    <Tab onClick={()=>handleToggleBtn("Amazone")} classes={{root:'tabLabelClass'}} value={"Amazone"}     label={
              <React.Fragment>
                <span className=''>Amazone</span>  
                <span className='countNumber'> 1</span>
              </React.Fragment>
            }  />
    <Tab onClick={()=>handleToggleBtn("Linkedin")} classes={{root:'tabLabelClass'}} value={"Linkedin"}  label={
              <React.Fragment>
                <span className=''>Linkedin</span>  
                <span className='countNumber'> 3</span>
              </React.Fragment>
            }   />
    <Tab onClick={()=>handleToggleBtn("Dice")} classes={{root:'tabLabelClass'}} value={"Dice"} label={
              <React.Fragment>
                <span className=''>Dice</span>  
                <span className='countNumber'> 0</span>
              </React.Fragment>
            }   />
  </Tabs>
</Box>

</>
  );
}

export default Jobtab;